import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { User } from "src/app/models/user.model";
import { Contact } from "src/app/models/contact.model";
import { AddUserRequest } from "src/app/models/add-user-request.model";
import { Role } from "src/app/models/role.model";
import { Store, select } from "@ngrx/store";
import * as fromReducers from "../../../../store/reducers";
import {
  map,
  debounceTime,
  distinctUntilChanged,
  withLatestFrom
} from "rxjs/operators";
import { Observable, combineLatest, Subscription } from "rxjs";
import { Customer } from "src/app/models/customer.model";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { Option } from 'src/app/models/option.model';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: "app-add-user-dialog",
  templateUrl: "./add-user-dialog.component.html",
  styleUrls: ["./add-user-dialog.component.scss"]
})
export class AddUserDialogComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<fromReducers.State>,
    private notificationService: NotificationService
  ) {}

  selectedCustomer: Customer;
  selectedContact: Contact;

  chosenPasswordOption: Option;
  passwordOptions = [
    {id:1,title:"Send Welcome Email"} as Option,
    {id:2,title:"Pre-Stage User (Send welcome email later)"} as Option
  ]

  customers$ = this.store.pipe(
    select(fromReducers.getCustomersAll),
    map(customers => customers.sort((a, b) => a.name.localeCompare(b.name)))
  );
  contacts$ = this.store.pipe(
    select(fromReducers.getContactsAll),
    map(contacts =>
      contacts.sort((a, b) =>
        (a.firstName + " " + a.lastName).localeCompare(
          b.firstName + " " + b.lastName
        )
      )
    )
  );
  contactsSubscription: Subscription;
  allContacts: Contact[];
  visibleContacts: Contact[] = [];

  // For now (phase 1), filter the roles to just the customer user
  roles$ = this.store
    .pipe(select(fromReducers.getRolesAll))
    .pipe(map(roles => roles.filter(x => x.id != 3)));

  createNewUserForm: UntypedFormGroup = new UntypedFormGroup({
    customerControl: new UntypedFormControl("", [Validators.required]),
    contactControl: new UntypedFormControl("", [Validators.required]),
    roleControl: new UntypedFormControl("", [Validators.required]),
    passwordOptionsControl: new UntypedFormControl("",[Validators.required])
  });

  updateSelectedCustomer(event: TypeaheadMatch) {
    const customer = event.item as Customer;
    this.selectedCustomer = customer;
    this.updateVisibleContacts();
  }
  updateSelectedContact(event: TypeaheadMatch) {
    const contact = event.item as Contact;
    this.selectedContact = contact;
  }

  ngOnInit() {
    this.contactsSubscription = this.contacts$.subscribe(contacts => {
      this.allContacts = contacts;
    });
  }

  ngOnDestroy(): void {
    if (this.contactsSubscription != null) {
      this.contactsSubscription.unsubscribe();
    }
  }

  updateVisibleContacts() {
    if (this.selectedCustomer == null) {
      this.visibleContacts = [];
    } else {
      this.visibleContacts = this.allContacts.filter(
        x => x.customer.id === this.selectedCustomer.id
      );
    }
  }

  createNewUser() {
    if (this.createNewUserForm.invalid) {
        this.notificationService.showError("Sorry, the form is invalid. Check all required fields.","close");
      return;
    }
    const selectedRole: Role = this.createNewUserForm.get("roleControl").value;
    // const sendWelcomeEmail: boolean = this.createNewUserForm.get(
    //   "sendWelcomeEmailControl"
    // ).value;
    const passwordOption: Option = this.createNewUserForm.get(
      "passwordOptionsControl"
    ).value;
    let sendWelcomeEmail = false;
    let manualPassword = false;
    let passwordStatus = passwordOption.id;
    if(passwordOption.id == 1){
      sendWelcomeEmail = true
    }
    else{
      sendWelcomeEmail = false;
      
      // if(passwordOption.id==3){
      //   manualPassword = true;
      // }
    }
    console.log("creating user with password status: ",passwordStatus);
    const addNewUserReq = {
      user: {
        email: this.selectedContact.emailAddress,
        crmContactId: this.selectedContact.id,
        role: selectedRole
      } as User,
      sendWelcomeEmail,
      manualPassword,
      passwordStatus
    } as AddUserRequest;

    this.dialogRef.close({
      req: addNewUserReq
    });
  }
}
