<h2 matDialogTitle>Create New Portal User</h2>
<mat-dialog-content class="text-center dialog" style="min-height: 400px;">
  <form [formGroup]="createNewUserForm">
    <mat-form-field class="full-width">
      <mat-label>Customer</mat-label>
      <input
        matInput
        type="text"
        formControlName="customerControl"
        [typeahead]="customers$ | async"
        [typeaheadOptionsLimit]="8"
        [typeaheadLatinize]="true"
        typeaheadOptionField="name"
        [typeaheadItemTemplate]="customerSearchTemplate"
        (typeaheadOnSelect)="updateSelectedCustomer($event)"
        autocomplete="off"
        required
      />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Contact</mat-label>
      <input
        matInput
        type="text"
        formControlName="contactControl"
        [typeahead]="visibleContacts"
        [typeaheadOptionsLimit]="8"
        [typeaheadMinLength]="0"
        [typeaheadLatinize]="true"
        typeaheadOptionField="emailAddress"
        [typeaheadItemTemplate]="contactSearchTemplate"
        (typeaheadOnSelect)="updateSelectedContact($event)"
        autocomplete="off"
        required
      />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Role</mat-label>
      <mat-select formControlName="roleControl" required>
        <mat-option *ngFor="let role of roles$ | async" [value]="role">{{
          role.name
        }}</mat-option>
      </mat-select>
      <mat-error>Please select a role for the user.</mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>Password Options</mat-label>
      <mat-select formControlName="passwordOptionsControl" [(ngModel)]="chosenPasswordOption" required>
        <mat-option *ngFor="let option of passwordOptions" [value]="option">{{
          option.title
        }}</mat-option>
      </mat-select>
      <mat-error>Please select a password option for the user.</mat-error>
    </mat-form-field>
    <!-- <mat-checkbox [(ngModel)]="sendWelcomeEmail" formControlName="sendWelcomeEmailControl"
      >Send Welcome Email
    </mat-checkbox> -->
    <div *ngIf="chosenPasswordOption">
      <p style="color:rgba(0, 0, 0, 0.42)" *ngIf="chosenPasswordOption.id==1">A welcome email will be sent to the user in order to set up their password.</p>
      <p style="color:rgba(0, 0, 0, 0.42)" *ngIf="chosenPasswordOption.id==2">A welcome email can be sent to the user at a later date.</p>
      <p style="color:rgba(0, 0, 0, 0.42)" *ngIf="chosenPasswordOption.id==3">
        <mat-icon style="vertical-align: bottom;">error_outline</mat-icon>
        Take note of the chosen user's email for registering a password.</p>
      <p style="color:rgba(0, 0, 0, 0.42)" *ngIf="chosenPasswordOption.id==3">
        <mat-icon style="vertical-align: bottom;">error_outline</mat-icon>
        This feature will be disabled in production for security reasons.</p>
    </div>
    

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="">CANCEL</button>
  <button mat-button (click)="createNewUser()">CREATE PORTAL ACCOUNT</button>
</mat-dialog-actions>

<ng-template #contactSearchTemplate let-contact="item" let-index="index">
  <p>
    {{ contact.firstName }} {{ contact.lastName }} ({{ contact.emailAddress }})
  </p>
</ng-template>

<ng-template #customerSearchTemplate let-customer="item" let-index="index">
  <p>
    {{ customer.name }}
  </p>
</ng-template>
